/* eslint-disable linebreak-style */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useEffect} from 'react';

// Palette
import palette from 'theme/palette';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Bar  } from 'react-chartjs-2';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent
} from 'components';

// Component styles
import styles from './styles';

import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getFpAvgPerMonth, getFPByMeter } from 'js/actions/meterDashboardActions';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

export default function FPDailyAvrgChart(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);

  const year = new Date().getFullYear();
  const lastYear = year - 1;
  const years = [year, lastYear];
  const { corpId, meterId } = ids;

  const comparativeYearTariff = useSelector(
    state => state.meterDashboard.comparativeYearTariff
  );

 

  const dataFP= [];
 

  const [data1, setData1] = React.useState( null );
  const [labelsDays,setLabelsDays] = React.useState( );
  const [selFPId,setSelFPId] = React.useState( null );
 



  const currDate = new Date();  
  const month= currDate.getMonth()+1;	// Month	[mm]	(1 - 12)
  const date= currDate.getDate();		// Day		[dd]	(1 - 31)
 

  const getFPResponse=( data  )=>{
    let fpId=null;

    let sMonth='' + month;
    if(month<10){
      sMonth='0' + month;
    }

    let sDate='' + date;
    if( date<10 ){
      sDate='0' + date;
    }

    let  startDate= '' +  year + sMonth;
    // startDate= "202203" ;

    let labels=[]

    for( var i=1;i<=31;i++ ){
      let sDay='' + i;
      if(i<10){
        sDay='0' + i;
      }
         
      labels.push( sDay );
    }

    setLabelsDays(labels);
    if(data && data.length>0  ){
      fpId=data[0].id;   
      setSelFPId(fpId);
      dispatch(getFpAvgPerMonth (  fpId , startDate   , getFpAvgPerMonthResponse )); 
     
    }
 
  }
    


  useEffect(() => {
    dispatch(getFPByMeter (  meterId  , getFPResponse ));
  }, [ ]);
    

  const getFpAvgPerMonthResponse=( data  )=>{   
    if (data) {        
      for( var i=0;i< data.length; i++ ){
        let item = data[i];
        dataFP.push ( item.value ) ;
      }
          
    }else{
      return;
    }



    let sMonth='' + month;
    if(month<10){
      sMonth='0' + month;
    }

    let sDate='' + date;
    if( date<10 ){
      sDate='0' + date;
    }

    let  startDate= '' +  year + sMonth;
    // startDate= "202203" ;

    let labels=[]

    if (data && data.length>0 ) {
      for( var i=1;i<=data.length;i++ ){
        let sDay='' + i;
        if(i<10){
          sDay='0' + i;
        }          
        labels.push( sDay );
      }
    }

 
    setData1( {
      labels: labels ,
      datasets: [
        {
          label: intl.formatMessage({id: 'app.meter.board.fpVariationInMonth.dataSet.labels.currMonth'}),
          yAxisID: 'A',
          // backgroundColor: palette.primary.main,
          backgroundColor: '#00AFEF',
          data: dataFP
        },
       
      ]
    }  );


 
    
  }

 

     
  const formatMoney=(value)=>{
    return '$ 0.9999';
  }
     
    
 
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: true },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary   ,
      callbacks: {
       
        /*
        label: function(tooltipItem, data) {         
          return  `${   data.datasets[tooltipItem.datasetIndex ].label + " $ "+ tooltipItem.value }`;
        }, */
        
        /*
        afterLabel: function(tooltipItem, data) {
           
               return  `${ "$" + tooltipItem.value }`;
        }*/
      },
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: palette.text.secondary  
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          id: 'A',
          position: 'left',
          ticks: {
            fontColor: palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      ]
    } 
  
  };
  

  if( !selFPId ){
    return null;

  }else{

    return (
        
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader
          noDivider
        >
          <PortletLabel    title={intl.formatMessage({id: 'app.meter.board.fpVariationInMonth.title'})} />
          <PortletToolbar />
        </PortletHeader>
        <PortletContent>
          <div  className={classes.chartWrapper}>
                
            <Bar
              data={data1}
              key={Math.random()}
              options={options}
              redraw
            />
                
          </div>
        </PortletContent>
      </Portlet>
    );
  }
}




FPDailyAvrgChart.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};
