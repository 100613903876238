/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { makeStyles, Typography, Button, Grid } from '@material-ui/core';

import { Paper } from 'components';
import MaterialTable from 'material-table';
import Swal from 'sweetalert2';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import { changeChargeStatus } from 'js/actions/chargeActions';

import VoltageIndicator from './VoltageIndicator';
const useStyles = makeStyles(styles);

export default function GroupCard(props) {
  const intl = useIntl();
  const { groupId } = props;
  const classes = useStyles();
  const {
    className,
    charge,
    setShowDetails,
    setSelectedCharge,
    setOwnerName,
    typeEnergy
  } = props;
  const rootClassName = classNames(classes.root, className);
  const dispatch = useDispatch();

  const { consumes } = charge;
  const consumesTable = [consumes[0], consumes[1]];

  const data = [];
  let obj;

  const dataSolar = [];
  let objSolar;

  const columns = [
    {
      title: '',
      field: 'med',
      cellStyle: {
        fontWeight: '900',
        fontSize: '1em',
        textAlign: 'center',
        width: '10%'
      }
    },
    {
      title: intl.formatMessage({
        id: 'app.chargesCards.card.gridCard.baseCol'
      }),
      field: 'bas',

      cellStyle: {
        backgroundColor: '#8CBA42',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({
        id: 'app.chargesCards.card.gridCard.intermidateCol'
      }),
      field: 'int',
      cellStyle: {
        backgroundColor: '#EAAB36',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({
        id: 'app.chargesCards.card.gridCard.tipCol'
      }),
      field: 'punt',
      cellStyle: {
        backgroundColor: '#D64539',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    }
  ];

  const columnsSolar = [
    {
      title: '',
      field: 'med',
      cellStyle: {
        fontWeight: '900',
        fontSize: '1em',
        textAlign: 'center',
        width: '10%'
      }
    },
    {
      title: intl.formatMessage({
        id:
            'app.chargeSolar.board.demandAndConsumpChart.dataSet.labels.base'
      }),
      field: 'bas',

      cellStyle: {
        backgroundColor: '#8CBA42',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({
        id: 'app.chargeSolar.board.demandAndConsumpChart.dataSet.labels.interm'
      }),
      field: 'int',
      cellStyle: {
        backgroundColor: '#EAAB36',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({
        id: 'app.chargeSolar.board.demandAndConsumpChart.dataSet.labels.tip'
      }),
      field: 'punt',
      cellStyle: {
        backgroundColor: '#D64539',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    }
  ];

  consumesTable.forEach(element => {
    obj = {
      med: element[0],
      bas: element[1] ? element[1].replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0,
      int: element[2] ? element[2].replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0,
      punt: element[3] ? element[3].replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
    };

    if (charge.charge_status == 0 || charge.type_unit == 'VOLTAJE') {
      obj = {
        med: element[0],
        bas: '',
        int: '',
        punt: ''
      };
    }

    data.push(obj);
  });

  consumesTable.forEach(element => {
    objSolar = {
      med: element[0],
      bas: element[1] ? element[1].replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0,
      int: element[1] ? element[2].replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0,
      punt: element[1] ? element[3].replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
    };

    if (charge.charge_status == 0 || charge.type_unit == 'VOLTAJE') {
      objSolar = {
        med: element[0],
        bas: '',
        int: '',
        punt: ''
      };
    }

    dataSolar.push(objSolar);
  });

  const handleChangeChargeStatus = () => {
    let txtTextKey = '';
    let newStatus = 0;

    if (charge.charge_status == 1) {
      txtTextKey =
        'app.chargesCards.card.gridCard.msgChangeChStat.text.turnOff';
      newStatus = 0;
    } else {
      txtTextKey = 'app.chargesCards.card.gridCard.msgChangeChStat.text.turnOn';
      newStatus = 1;
    }

    Swal.fire({
      title: intl.formatMessage({
        id: 'app.chargesCards.card.gridCard.msgChangeChStat.title'
      }),
      text: intl.formatMessage({ id: txtTextKey }),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: intl.formatMessage({
        id: 'app.chargesCards.card.gridCard.msgChangeChStat.btnConfirm'
      }),
      cancelButtonText: intl.formatMessage({
        id: 'app.chargesCards.card.gridCard.msgChangeChStat.btnCancel'
      })
    }).then(result => {
      if (result.value == true) {
        dispatch(changeChargeStatus(charge, newStatus, groupId));
      }
    });
  };

  const displayChargeStatusIcon = () => {
    if (charge.charge_status == 1) {
      return (
        <div style={{ textAlign: 'left', verticalAlign: 'top' }}>
          <img
            onClick={handleChangeChargeStatus}
            src="/images/charge_turn_on_count.png"
            style={{ width: '22px', height: '22px', marginBottom: '15px' }}
          />
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: 'left', verticalAlign: 'top' }}>
          <img
            onClick={handleChangeChargeStatus}
            src="/images/charge_turn_off_count.png"
            style={{ width: '22px', height: '22px', marginBottom: '15px' }}
          />
        </div>
      );
    }
  };

  const displayButton = () => {
    if (charge.type_unit == 'VOLTAJE') {
      return (
        <Link
          to={{
            pathname: `/group/${groupId}/charge/${charge.id}/alert`,
            state: {
              charge: charge
            }
          }}
        >
          <Button
            style={{
              width: '100%',
              backgroundColor: '#D64539',
              color: 'white'
            }}
            variant="contained"
          >
            {intl.formatMessage({ id: 'app.metersCards.card.voltageAlert' })}
          </Button>
        </Link>
      );
    } else {
      return (
        <Link
          to={{
            pathname:
              typeEnergy === 2
                ? `/dashboardSolarCharge/group/${groupId}/charge/${charge.id}`
                : `/dashboardCharge/group/${groupId}/charge/${charge.id}`,
            state: {
              charge: charge
              //meter: meter,
              //group: group
            }
          }}
        >
          <Button
            color="secondary"
            style={{ width: '100%', backgroundColor:  typeEnergy === 2 ? '#45B880' : '' }}
            variant="contained"
          >
            {intl.formatMessage({ id: 'app.chargesCards.card.boardBtn' })}{' '}
            {typeEnergy === 2 ? 'Solar' : ''}
          </Button>
        </Link>
      );
    }
  };

  const displayCo2Info = () => {
    if (charge.type_unit == 'VOLTAJE') {
      return (
        <div>
          <img
            src="/images/icono3_co2.png"
            style={{ width: '50px', height: '50px' }}
          />
          <Typography style={{ color: '#FF8024', fontWeight: 'bold' }}>
            {charge.charge_status == 0 ? '' : charge.co2 + ' kg *'}
          </Typography>
        </div>
      );
    } else {
      return (
        <div>
          <img
            src={typeEnergy === 2 ?  '/images/co2-icon.png' : '/images/icono3_co2.png'}
            style={{ width: '50px' }}
          />
          <Typography style={{ color: typeEnergy === 2 ?  '#45B880' : '#FF8024', fontWeight: 'bold' }}>
            {charge.charge_status == 0 ? '' : charge.co2 + ' kg *'}
          </Typography >
        </div>
      );
    }
  };

  const displayChargeName = () => {
    if (charge.is_father == 1) {
      return (
        <Typography variant="h5">
          {charge.name}
          <br />
        </Typography>
      );
    } else {
      return (
        <Typography variant="h5">
          {charge.name}
          <br />
        </Typography>
      );
    }
  };

  const displayVoltage = () => {
    return (
      <div
        style={{
          fontFamily: 'Lato, Helvetica Neue,Arial',
          textAlign: 'center'
        }}
      >
        <VoltageIndicator charge={charge} />
      </div>
    );
  };

  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const sunFill = () => {
    const consumeFake = Math.floor(Math.random() * (100 - 0)) + 0;

    if (consumeFake === 100) {
      return (
        <Grid
          lg={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '1rem'
          }}
          xs={6}
        >
          <img
            src="/images/sol-05.png"
            style={{ width: '25%', height: '100%' }}
          />
          <Typography style={{ color: '#fcc02d', fontWeight: 'bold' }}>
            {consumeFake} kWh
          </Typography>
        </Grid>
      );
    } else if (consumeFake >= 75) {
      return (
        <Grid
          lg={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '1rem'
          }}
          xs={6}
        >
          <img
            src="/images/sol-04.png"
            style={{ width: '25%', height: '100%' }}
          />
          <Typography style={{ color: '#fcc02d', fontWeight: 'bold' }}>
            {consumeFake} kWh
          </Typography>
        </Grid>
      );
    } else if (consumeFake >= 45) {
      return (
        <Grid
          lg={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '1rem'
          }}
          xs={6}
        >
          <img
            src="/images/sol-03.png"
            style={{ width: '25%', height: '100%' }}
          />
          <Typography style={{ color: '#fcc02d', fontWeight: 'bold' }}>
            {consumeFake} kWh
          </Typography>
        </Grid>
      );
    } else if (consumeFake >= 25) {
      return (
        <Grid
          lg={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '1rem'
          }}
          xs={6}
        >
          <img
            src="/images/sol-02.png"
            style={{ width: '25%', height: '100%' }}
          />
          <Typography style={{ color: '#fcc02d', fontWeight: 'bold' }}>
            {consumeFake} kWh
          </Typography>
        </Grid>
      );
    } else {
      return (
        <Grid
          lg={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '1rem'
          }}
          xs={6}
        >
          <img
            src="/images/sol-01.png"
            style={{ width: '25%', height: '100%' }}
          />
          <Typography style={{ color: '#fcc02d', fontWeight: 'bold' }}>
            {consumeFake} kWh
          </Typography>
        </Grid>
      );
    }
  };

  const handleShowDetails = () => {
    setSelectedCharge(charge);
    setShowDetails(true);
  };

  return (
    <Paper className={rootClassName}>
      <div className={classes.details}>
        {typeEnergy === 2 ? (
          <Grid
            container
            spacing={1}
          >
            <Grid
              alignItems="flex-start"
              container
              item
              justifyContent="space-between"
              spacing={1}
              style={{
                margin: '2px 2px .5rem',
                justifyContent: 'space-between'
              }}
              xs={12}
            >
              {sunFill()}
              <Grid
                className={classes.buttonMore}
                item
                lg={6}
                xs={6}
              >
                <Button
                  onClick={handleShowDetails}
                  style={{backgroundColor: '#45B880', color: 'white'}}
                  variant="contained"
                >
                  {intl.formatMessage({ id: 'app.details.btn' })}
                </Button>
              </Grid>
              <Grid
                container
                direction="column"
                item
                justify="top"
                justifyContent="top"
                lg={6}
                style={{ padding: '2px' }}
                xs={6}
              >
                {displayChargeStatusIcon()}

                {displayChargeName()}

                <div style={{ height: '15px' }} />
              </Grid>
              <Grid
                alignItems="center"
                direction="column"
                item
                justifyContent="center"
                lg={4}
                style={{ textAlign: 'right' }}
                xs={4}
              >
                <div>{displayCo2Info()}</div>
              </Grid>
            </Grid>
            <Grid
              className={classes.tableCellRoot}
              item
              xs={12}
            >
              <MaterialTable
                columns={columnsSolar}
                data={dataSolar}
                options={{
                  filtering: false,
                  search: false,
                  sorting: false,
                  toolbar: false,
                  paging: false,
                  padding: 0,

                  rowStyle: {
                    fontSize: 15
                  },
                  headerStyle: {
                    textAlign: 'center',
                    padding: '0',
                    fontSize: '0.8em',
                    fontWeight: '900',
                    minWidth: '25%'
                  }
                }}
                style={{boxShadow: '0px 1px 5px 5px rgba(69, 184, 128, 0.5), 0px 2px 2px 0px rgba(69, 184, 128, 0.5), 0px 3px 1px -2px rgba(69, 184, 128, 0.5)'}}
              />
            </Grid>
            <Grid
              item
              lg={12}
              style={{
                display: 'flex',
                gap: '2rem',
                justifyContent: 'flex-end',
                marginRight: '1rem'
              }}
              xs={12}
            >
              <Typography
                className={classes.description}
                style={{ marginTop: '10px', color: '#000', fontWeight: '600' }}
                variant="h5"
              >
                {intl.formatMessage({
                  id: 'app.chargesSolarCards.card.estAmtPerDay'
                })}
              </Typography>
              <Typography
                className={classes.description}
                style={{
                  color: '#218A1D',
                  fontWeight: '600',
                  marginTop: '10px'
                }}
                variant="h5"
              >
                {charge.charge_status == 0 ? '' : '$ ' + charge.total}
              </Typography>
            </Grid>
            {/* <Grid
              item
              style={{ textAlign: 'center' }}
              xs={12}
            >
              {displayVoltage()}
            </Grid> */}
            <Grid
              item
              xs={12}
            >
              {displayButton()}
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography
                style={{
                  fontSize: '.9em',
                  textAlign: 'center',
                  visibility: 'visible'
                }}
                variant="body1"
              >
                {intl.formatMessage({
                  id: 'app.chargesCards.card.meassCurrMonth'
                })}
              </Typography>
              <Typography
                style={{
                  fontSize: '.9em',
                  textAlign: 'center',
                  visibility: 'visible'
                }}
                variant="body1"
              >
                {intl.formatMessage({ id: 'app.chargesCards.card.co2Msg' })}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            spacing={1}
          >
            <Grid
              alignItems="flex-start"
              container
              item
              justifyContent="space-between"
              spacing={1}
              style={{
                margin: '2px 2px 1rem',
                justifyContent: 'space-between'
              }}
              xs={12}
            >
              <Grid
                className={classes.buttonMore}
                item
                lg={12}
                xs={12}
              >
                <Button
                  color="secondary"
                  onClick={handleShowDetails}
                  variant="contained"
                >
                  {intl.formatMessage({ id: 'app.details.btn' })}
                </Button>
              </Grid>
              <Grid
                container
                direction="column"
                item
                justify="top"
                justifyContent="top"
                lg={6}
                style={{ padding: '2px' }}
                xs={6}
              >
                {displayChargeStatusIcon()}

                {displayChargeName()}

                <div style={{ height: '15px' }} />
              </Grid>
              <Grid
                alignItems="center"
                direction="column"
                item
                justifyContent="center"
                lg={4}
                style={{ textAlign: 'right' }}
                xs={4}
              >
                <div>{displayCo2Info()}</div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <MaterialTable
                columns={columns}
                data={data}
                options={{
                  filtering: false,
                  search: false,
                  sorting: false,
                  toolbar: false,
                  paging: false,
                  padding: 0,

                  rowStyle: {
                    fontSize: 15
                  },
                  headerStyle: {
                    textAlign: 'center',
                    padding: '0',
                    fontSize: '0.8em',
                    fontWeight: '900',
                    minWidth: '25%'
                  }
                }}
              />
            </Grid>
            <Grid
              item
              lg={12}
              style={{
                display: 'flex',
                gap: '2rem',
                justifyContent: 'flex-end',
                marginRight: '1rem'
              }}
              xs={12}
            >
              <Typography
                className={classes.description}
                style={{ marginTop: '10px', color: '#000', fontWeight: '600' }}
                variant="h5"
              >
                {intl.formatMessage({
                  id: 'app.chargesCards.card.estAmtPerDay'
                })}
              </Typography>
              <Typography
                className={classes.description}
                style={{
                  color: '#218A1D',
                  fontWeight: '600',
                  marginTop: '10px'
                }}
                variant="h5"
              >
                {charge.charge_status == 0 ? '' : '$ ' + charge.total}
              </Typography>
            </Grid>
            <Grid
              item
              style={{ textAlign: 'center' }}
              xs={12}
            >
              {displayVoltage()}
            </Grid>
            <Grid
              item
              xs={12}
            >
              {displayButton()}
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography
                style={{
                  fontSize: '.9em',
                  textAlign: 'center',
                  visibility: 'visible'
                }}
                variant="body1"
              >
                {intl.formatMessage({
                  id: 'app.chargesCards.card.meassCurrMonth'
                })}
              </Typography>
              <Typography
                style={{
                  fontSize: '.9em',
                  textAlign: 'center',
                  visibility: 'visible'
                }}
                variant="body1"
              >
                {intl.formatMessage({ id: 'app.chargesCards.card.co2Msg' })}
              </Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </Paper>
  );
}
