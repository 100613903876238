export default theme => ({
  root: {
    maxWidth: '100%',
    padding:'16px',
    height: '725px',
    maxHeight: '750px',
    [theme.breakpoints.down('md')]:{
      maxHeight: 'fit-content',
      height: 'auto'
    }
  },
  heightLimit:{
    height: '2.2rem',
    marginBottom: '5px'
  },
  imageWrapper: {
    height: '64px',
    width: '64px',
    margin: '0 auto',
    border: '1px solid #EDF0F2',
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  },
  details: {},
  title: {
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'center',
    marginTop: theme.spacing.unit * 2
  },
  description: {
    lineHeight: '16px',
    height: '20px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    marginTop: theme.spacing.unit,
    marginBottom: '5px'
  },
  stats: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing.unit
  },
  buttonMore: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: '1.25rem'
  }
    
});
