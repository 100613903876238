import * as meterActionTypes from 'js/constants/meterActions-types';
import axios from 'axios';
import getToken from 'helpers/auth';
import { history } from 'helpers/history';
import Swal from 'sweetalert2'



const getconfig = () => {
  return {
    headers: { Authorization: 'Bearer ' + getToken() }
  };
};

export const showMeters = corporativeID => {
  return async dispatch => {
    dispatch(getMeterStarted());
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corporativeID}`,
        getconfig()
      )
      .then(response => {
        dispatch(getMeter(response.data.data.meters));
      })
      .catch(function(error) {
        dispatch(getMeterFailure());
        console.log(error);
      });
  };
};

export const getMeterStarted = () => ({
  type: meterActionTypes.GET_METER_STARTED,
  loading: true
});


export const getMeter = meters => {
  return {
    type: meterActionTypes.GET_METER_SUCCESS,
    meters,
    loading: false
  };
};


export const getMeterFailure = () => {
  return {
    type: meterActionTypes.GET_METER_FAILURE ,
    
  };
};

export const showAllMeters = corporativeID => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/corporative/${corporativeID}/meters`,
        getconfig()
      )
      .then(response => {
        dispatch(getMeter(response.data.data));
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};



export const postDataMeter = (meter, corpId) => {
  return async dispatch => {
    dispatch(postMeterStarted());

    axios
      .post(
        'https://api.metimur.mx/api/meter',
        {
          name: meter.name,
          description: meter.description,
          country: meter.country,
          state: meter.state,
          city: meter.city,
          county: meter.county,
          cp: meter.cp,
          address: meter.address, 
          payment_deadline: meter.payment_deadline ,
          corporative_id: corpId ,
          contracted_demand : meter.contracted_demand
        },
        getconfig()
      )
      .then(response => {
        Swal.fire({
          title: 'Alta Correcta',
          text: `Medidor ${meter.name} creado exitosamente`,
          icon: 'success',
        }).then(() => {
          history.push(`/corporative/${corpId}`);
        });
        dispatch(postMeterSuccess(meter));
      })
      .catch(error => {
        const errorMessage = error.response.data.message
        Swal.fire({
          title: 'Error',
          text: `${errorMessage.join()}`,
          icon: 'error',
        });
        dispatch(postMeterFail(error.message));
      });
  };
};

export const postMeterStarted = () => ({
  type: meterActionTypes.POST_METER_STARTED
});

export const postMeterSuccess = data => {
  return {
    type: meterActionTypes.POST_METER_SUCCESS,
    payload: {
      name: data.name,
      description: data.description,
      country: data.country,
      state: data.cstate,
      city: data.city,
      county: data.county,
      cp: data.cp,
      address: data.address,
      corporative_id: data.corporative_id
    }
  };
};

export const postMeterFail = error => ({
  type: meterActionTypes.POST_METER_FAILURE,
  state: { error }
});

export const deleteMeter = (meterId, corpId) => {
  return async dispatch => {
    dispatch(deleteMeterSuccess(meterId));
    axios.post(
      'https://api.metimur.mx/api/meter/delete',
      { meter_id: meterId, corporative_id: corpId },
      getconfig()
    );
  };
};

export const deleteMeterSuccess = meterId => {
  return {
    type: meterActionTypes.DELETE_METER_SUCCESS,
    payload: { id: meterId }
  };
};


export const updateDataMeter = (meter, meterData,corpId) => {
  return async dispatch => {
    dispatch(updateMeterStarted());

    axios
      .put(
        'https://api.metimur.mx/api/meter',
        {
          meter_id: meterData.id,
          name: meter.name,
          description: meter.description,
          country: meter.country,
          state: meter.state,
          city: meter.city,
          county: meter.county,
          cp: meter.cp,
          address: meter.address, 
          payment_deadline: meter.payment_deadline ,
          corporative_id: meterData.corporative_id ,
          contracted_demand : meter.contracted_demand
        },
        getconfig()
      )
      .then(() => {
        Swal.fire({
          title: 'Actualizacion Correcta',
          text: `Medidor ${meter.name} actualizado exitosamente`,
          icon: 'success',
        }).then(() => {
          history.push(`/corporative/${meterData.corporative_id}`);
        });
      })
      .catch(error => {
        const errorMessage = error.response.data.message
        Swal.fire({
          title: 'Error',
          text: `${errorMessage.join()}`,
          icon: 'error',
        });
        dispatch(postMeterFail(error.message));
      });
  };
};

export const updateMeterStarted = () => ({
  type: meterActionTypes.PUT_METER_STARTED
});


export const updateDataInvoiceMeter = (meter, meterData,corpId) => {
  return async dispatch => {
   // dispatch(updateMeterStarted());
      
    axios
      .post(
        'https://api.metimur.mx/api/meter/invoice',
        {

          meter_id              : meterData.id ,
          administrative_rfc    : meter.administrative_rfc ,
          administrative_name   : meter.administrative_name  ,
          administrative_cp     : meter.administrative_cp ,
          administrative_email  : meter.administrative_email ,
          administrative_email2 : meter.administrative_email2 ,
          administrative_tel    : meter.administrative_tel   ,

          calle : meter.calle , 
          codigo_postal : meter.cp ,
          numero_exterior : meter.numExt ,
          colonia : meter.colonia ,
          estado : meter.state ,
          municipio : meter.city ,
          pais : '52'

 

        },
        getconfig()
      )
      .then(() => {
        Swal.fire({
          title: 'Actualizacion Correcta',
          text:  'Los datos de facturación se actualizarón exitosamente ' ,
          icon: 'success',
        }).then(() => {
          history.push(`/corporative/${meterData.corporative_id}`);
        });
      })
      .catch(error => {
        dispatch(postMeterFail(error.message));
        const errorMessage = error.response.data.message
          
        Swal.fire({
          title: 'Error',
          text: `<br/>${errorMessage.join('<br/>')}`,
          icon: 'error',
        });
      });
  };
};


/*
export const updateDataInvoiceMeter = (meter, meterData,corpId) => {
  return async dispatch => {
   // dispatch(updateMeterStarted());
      alert("updateDataInvoiceMeter")
    axios
      .put(
        'https://api.metimur.mx/api/meter/invoice',
        {

          meter_id              : meterData.id ,
          administrative_rfc    : meter.administrative_rfc ,
          administrative_name   : meter.administrative_name  ,
          administrative_cp     : meter.administrative_cp ,
          administrative_email  : meter.administrative_email ,
          administrative_email2 : meter.administrative_email2 ,
          administrative_tel    : meter.administrative_tel 
        },
        getconfig()
      )
      .then(() => {
        Swal.fire({
          title: 'Actualizacion Correcta',
          text:  'Los datos de facturación se actualizarón exitosamente ' ,
          icon: 'success',
        }).then(() => {
          history.push(`/corporative/${meterData.corporative_id}`);
        });
      })
      .catch(error => {
        dispatch(postMeterFail(error.message));
        console.log('ERROR API:' + error);
      });
  };
};
*/



export const getDataInvoiceMeter = ( meterID , funcResp ) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/meter/${meterID}/invoicedata`,
        getconfig()
      )
      .then(response => {
        funcResp(response.data.data );
      })
      .catch(function(error) {
        console.log(error);
      });
  };
};


export const getConsumtionAndWeight = (meterId , funcResp ) => {
  return async dispatch => {
    axios
      .get(
        `https://api.metimur.mx/api/meter/${meterId}/billing`,
        getconfig()
      )
      .then(response => {
        funcResp(response.data.data);
      })
      .catch(function(error) {
        console.log('error:' + error);
      });
  };
};