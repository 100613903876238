import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import regression from 'regression';

// Externals
import classNames from 'classnames';
import { Line } from 'react-chartjs-2';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Component styles
import styles from './styles.jsx';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import moment from 'moment';

const useStyles = makeStyles(styles);

// Function to calculate the linear regression
// function calculateTrendLine(data) {
//   const points = data.map((value, index) => [index + 1, value]);
//   const result = regression.linear(points);
//   const slope = result.equation[0];
//   const intercept = result.equation[1];
//   return data.map((_, i) => {
//     const trendValue = slope * (i + 1) + intercept;
//     return Math.max(0, trendValue);
//   });
// }

export default function ConsumptionAndDemandChart(props) {
  const intl = useIntl();
  const classes = useStyles();
  const { className, consumes, ...rest } = props;
  const generalReport = useSelector(
    state => state.chargeDashboard.generalReport.total_per_day_kw
  );

  const [days, setDays] = useState([]);
  const localeNav = useSelector(state => state.prefs.locale);

  useEffect(() => {
    const daysInMonth = moment().daysInMonth();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const daysArray = [];
    moment.locale(localeNav);
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment({ year: currentYear, month: currentMonth, day: i });
      daysArray.push({
        day: i,
        weekday: date.format('ddd')
      });
    }

    setDays(daysArray);
  }, [localeNav]);

  const labels = days.map(
    day =>
      `${day.weekday.charAt(0).toUpperCase() + day.weekday.slice(1, 3)} ${
        day.day
      }`
  );

  const data = {
    labels: labels,
    datasets: [
      {
        data: [],
        label: intl.formatMessage({
          id: 'app.chargeSolar.board.demandPerDay.dataSet.labels.demand'
        }),
        fill: false,
        lineTension: 0.3,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt'
      }
      // {
      //   data: [],
      //   label: intl.formatMessage({ id: 'app.charge.board.dailyAcumConsump.dataSet.tendency' }),
      //   fill: false,
      //   lineTension: 0.3,
      //   backgroundColor: 'rgba(255,99,132,0.4)',
      //   borderColor: 'rgba(255,99,132,1)',
      //   borderCapStyle: 'butt',
      //   borderDash: [5, 5],
      // },
    ]
  };

  if (generalReport) {
    const maxValues = [];
    Object.keys(generalReport).forEach(key => {
      const element = generalReport[key];
      const day = parseInt(key, 10) - 1;
      data.datasets[0].data[day] = parseFloat(element.max);
      if (parseFloat(element.max) > 0) {
        maxValues.push(parseFloat(element.max));
      }
    });

    // const trendLine = calculateTrendLine(maxValues);

    // trendLine.forEach((value, index) => {
    //   data.datasets[1].data[index] = value;
    // });

    // const result = regression.linear(trendLine.map((val, idx) => [idx + 1, val]));
    // const slope = result.equation[0];
    // const intercept = result.equation[1];

    // for (let i = maxValues.length; i < 31; i++) {
    //   const y = slope * (i + 1) + intercept;
    //   data.datasets[1].data[i] = Math.max(0, y);
    // }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    animation: {
      onComplete: function() {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.textBaseline = 'bottom';

        this.data.datasets[0].data.forEach(function(data, index) {
          const meta = chartInstance.controller.getDatasetMeta(0);
          const bar = meta.data[index];
          const value = data;
          ctx.fillText(value, bar._model.x, bar._model.y - 5);
        });
      }
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          return `${dataset.label}: ${value.toFixed(2)}`;
        }
      }
    },
    scales: {
      yAxes: [
        {
          position: 'right',
          ticks: {
            callback: function(value) {
              return value.toFixed(1);
            },
            beginAtZero: true
          }
        }
      ]
    }
  };

  const dataHours = [];
  let obj;

  /*
if(  generalReport ){

  Object.values(generalReport).forEach((element, index) => {
    element.forEach(element2=>{
      obj = {
        hora: element2.Hora,
        medicion: element2.Kw,
        color: element2.color
      };
      dataHours.push(obj);
    })

    //data.datasets[0].data.push( Math.max.apply(Math, element.map(function(o) { return o.Kw; })).toFixed(2))
  })


} */

  /*
  const res = dataHours.reduce((acc, curr) => {
    if (!acc[curr.hora]) acc[curr.hora] = []; //If this type wasn't previously stored
    acc[curr.hora].push(curr);
    return acc;
  }, {}); */

  /*
  Object.values(res).sort((a, b) => a[0].hora - b[0].hora).forEach(element =>{
    data.labels.push(`${element[0].hora}`)

    let consumesARRAY = []

    element.forEach(element2 => {
      consumesARRAY.push(element2.medicion)
    });

    console.log(consumesARRAY)

    data.datasets.push(
      {
        label:'Demanda',
        fill: false,
        lineTension: 0.3,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        data: consumesARRAY
      }
    )
  })

  
*/

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel
          title={intl.formatMessage({
            id: 'app.chargeSolar.board.demandPerDay.title'
          })}
        />
        {/* <PortletToolbar>
          <Button
            className={classes.dropdownButton}
            size="small"
            variant="text">
            Septiembre
          </Button>
        </PortletToolbar> */}
      </PortletHeader>
      <PortletContent>
        <div className={classes.chartWrapper}>
          <Line
            data={data}
            options={options}
          />
        </div>
      </PortletContent>
      {/* <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary" 
            size="small"
            variant="text"
          >
            Overview <ArrowRightIcon />
          </Button>
        </PortletFooter> */}
    </Portlet>
  );
}
