/* eslint-disable react/jsx-closing-bracket-location */
import React, { useState } from 'react';

// Material helpers
import { makeStyles, Typography } from '@material-ui/core';


// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';
import { Container, Button } from 'react-floating-action-button';
import { Link as LinkFloat } from 'react-floating-action-button';
import { isAllowed } from 'helpers/auth';
import { useSelector } from 'react-redux';


// Custom components
import { GridCorporative } from './components';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import theme from 'theme';
const useStyles = makeStyles(styles);

export default function ProductList() {

  const intl = useIntl();
  const classes = useStyles();
  const user = useSelector(state=>state.user.user)

  const [showEditButton, setShowEditButton] = useState(false)
  const [showDeleteButton, setShowDeleteButton] = useState(false)

  return (
    <DashboardLayout title={intl.formatMessage({id: 'app.corporate.headTitle'})}>
      <div className={classes.root}>
        <Typography
          style={{color:'#A53693', marginBottom: theme.spacing.unit * 2 }}
          variant="h2"
        >
          {intl.formatMessage({id: 'app.corporate.headTitle'})}
        </Typography>
        {/* <ProductsToolbar /> */}
        <div className={classes.content}>
        
          <GridCorporative 
            deleteButton={showDeleteButton}
            editButton={showEditButton}
          />
        </div>
      </div>
      <Container className={classes.buttonContainer} >
        {isAllowed(user.permissions,['corporative_update'])&&<Button
          icon="fa fa-trash"
          onClick = {()=>setShowDeleteButton(!showDeleteButton)}
          styles={{
            backgroundColor: '#00AFEF',
            color: '#fff',
            width: '60px',
            height: '60px',
            fontSize: '1.5em'
          }}
          tooltip={intl.formatMessage({id: 'app.corporate.deleteCorp'})}
        />}
        {isAllowed(user.permissions,['corporative_update'])&& <Button
          icon="fa fa-pencil-alt"
          onClick = {()=>setShowEditButton(!showEditButton)}
          styles={{
            backgroundColor: '#00AFEF',
            color: '#fff',
            width: '60px',
            height: '60px',
            fontSize: '1.5em'
          }}
          tooltip={intl.formatMessage({id: 'app.corporate.editCorp'})}
        />}
        {isAllowed(user.permissions,['corporative_read'])&&<LinkFloat
          href={'/newCorporation'}
          icon="fa fa-plus"
          styles={{
            backgroundColor: '#00AFEF',
            color: '#fff',
            width: '60px',
            height: '60px',
            fontSize: '1.5em'
          }}
          tooltip={intl.formatMessage({id: 'app.corporate.newCorp'})}
        />}
        <Button
          icon="fa fa-cog"
          rotate
          styles={{
            backgroundColor: '#C69100',
            color: '#fff',
            width: '80px',
            height: '80px',
            fontSize: '1.5em'
          }}
        />
      </Container>
    </DashboardLayout>  
  );
}
