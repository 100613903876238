import * as dashboardChargeActionTypes from '../constants/chargeDashboard-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function concentratorReducer(
  state = {
    generalConsumes:[],
    monthMostConsume: [],
    dayMostConsume: [],
    comparativeYearConsume: [],
    generalReport:[],
    receipt:[],
    historicalReport: null,
    loading: true
  },
  action
) {
  switch (action.type) {
    case dashboardChargeActionTypes.GET_GENERAL_CONSUMES_SUCCESS:
      return { ...state, generalConsumes: action.generalConsume};
        
    case dashboardChargeActionTypes.GET_MONTH_MOST_CONSUME_SUCCESS:
      return { ...state, monthMostConsume: action.chartMonthMostConsume};

    case dashboardChargeActionTypes.GET_DAY_WITH_MOST_CONSUME_SUCCESS:
      return { ...state, dayMostConsume: action.chartDayMostConsume};

    case dashboardChargeActionTypes.GET_COMPARATIVE_YEARS_CONSUME_SUCCESS_CHARGE :
      return { ...state, comparativeYearConsume: action.chartComparativeYearConsume};
    case dashboardChargeActionTypes.GET_GENERAL_REPORT_SUCCESS:
      return { ...state, generalReport: action.generalReport};
    case dashboardChargeActionTypes.GET_RECEIPT_SUCCESS:
      return { ...state, receipt: action.receipt};
    case dashboardChargeActionTypes.GET_HISTORICAL_REPORT_STARTED:
      return { ...state, loading: true}
    case dashboardChargeActionTypes.GET_HISTORICAL_REPORT_SUCCESS:
      return { ...state, historicalReport: action.historicalReport, loading: false};
    case dashboardChargeActionTypes.GET_HISTORICAL_REPORT_FAILURE:
      return { ...state, loading: false};
    default:
      return state;
  }
}

export default concentratorReducer;
