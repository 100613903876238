export default theme => ({
  root: {},
  buttonContainer: {
    marginRight: '-120px',
    marginBottom: '-20px',
    zIndex: '900',
    [theme.breakpoints.down('lg')]: {
      marginRight: '-90px'
    },
    [theme.breakpoints.down('md')]: {
      marginRight: '-20px'
    },
  }
});