/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';

// Externals
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar'

//import { getGeneralReport } from 'js/actions/chargeDashboardActions';

// Material helpers
import {
  makeStyles,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Table,
  TableBody
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
const useStyles = makeStyles(styles);

export default function RepAveragePerDayOfWeek(props) {
  const intl = useIntl()
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids, valueInfo } = props;
  const generalReport = useSelector(
    state => state.chargeDashboard.generalReport.report
  );

  const totalsDaysOfWeek = useSelector(
    state => state.chargeDashboard.generalReport.totals_days_of_week_hm
  );

  const totalsDaysOfWeekCost = useSelector(
    state => state.chargeDashboard.generalReport.cost_totals_days_of_week
  )

  const reorderDaysOfWeek = (totals) => {
    return [
      totals['7'],
      totals['1'],
      totals['2'],
      totals['3'],
      totals['4'],
      totals['5'],
      totals['6'],
    ].filter(Boolean);
  };

  const reorderedTotalsDaysOfWeek = totalsDaysOfWeek ? reorderDaysOfWeek(totalsDaysOfWeek) : [];
  const reorderedTotalsDaysOfWeekCost = totalsDaysOfWeekCost ? reorderDaysOfWeek(totalsDaysOfWeekCost) : [];

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography
          style={{ marginBottom: '0px' , marginLeft:'10px' , marginTop: '1.5rem', textAlign: 'center'  }}
          variant="h5"
        >
          {valueInfo.kwh ? intl.formatMessage({id: 'app.charge.board.avgPerDayOfWeek.title'}) : intl.formatMessage({id: 'app.charge.board.avgPerDayOfWeek.titleCost'})}
        </Typography>
        <PerfectScrollbar>
          <Table
            className={classes.table}
            size="medium"
            style={{ width: '100%', minWidth: '0' }}
          >
            <TableHead>
              <TableRow>
                <TableCell> {intl.formatMessage({id: 'app.daysOfWeek.sun'})} </TableCell>
                <TableCell> {intl.formatMessage({id: 'app.daysOfWeek.mon'})} </TableCell>
                <TableCell> {intl.formatMessage({id: 'app.daysOfWeek.tue'})} </TableCell>
                <TableCell> {intl.formatMessage({id: 'app.daysOfWeek.wen'})} </TableCell>
                <TableCell> {intl.formatMessage({id: 'app.daysOfWeek.thu'})} </TableCell>
                <TableCell> {intl.formatMessage({id: 'app.daysOfWeek.fri'})} </TableCell>
                <TableCell> {intl.formatMessage({id: 'app.daysOfWeek.sat'})} </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ padding: '0 !important' }}>
              {generalReport != undefined || generalReport != null ? (<TableRow style={{ height: '0px' }}>
                {valueInfo.kwh ? 
                  reorderedTotalsDaysOfWeek.map(row => (
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ fontSize: '1em',  fontWeight: 'bold', backgroundColor: row.color_hm }}
                    >
                      {row.value}
                    </TableCell>
                  ))
                  :
                  reorderedTotalsDaysOfWeekCost.map(row => (
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ fontSize: '1em',  fontWeight: 'bold', backgroundColor: row.color_cost}}
                    >
                      {formatter.format(row.cost)}
                    </TableCell>
                  ))
                }
              </TableRow>): (null) }
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Paper>
    </div>
  );
}
