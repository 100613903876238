/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


import { Typography , makeStyles,Breadcrumbs,Link as LinkMaterial } from '@material-ui/core';

import { NavLink } from 'react-router-dom';
import { isAllowed } from 'helpers/auth';


// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { GridGroup,} from './components';


// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import theme from 'theme';
const useStyles = makeStyles(styles)

export default function ProductList(props) {
  const intl = useIntl()
  const {meterId} = props.match.params 
  const classes = useStyles()
  const {meter} = props.location.state || {};

  const user = useSelector(state=>state.user.user)
  //const concentratorid = props.props;
  const corpAdmin = useSelector(state=>state.user.corporative)
  const corpUser = user.corporative
  const corporativeId = corpUser === undefined || corpUser === null ? corpAdmin.id : corpUser.id

  const storedMeterName = localStorage.getItem(`meterName_${meterId}`);

  useEffect(() => {
    if (meter && meter.name) {
      localStorage.setItem(`meterName_${meterId}`, meter.name);
    }
  }, [meter, meterId]);

  const storedCorpName = localStorage.getItem(`corpName_${corporativeId}`);

  useEffect(() => {
    if (corpAdmin && corpAdmin.name) {
      localStorage.setItem(`corpName_${corporativeId}`, corpAdmin.name);
    }
  }, [corpAdmin, corporativeId]);

  const corpName = corpAdmin && corpAdmin.name ? corpAdmin.name : storedCorpName;
  const meterName = meter && meter.name ? meter.name : storedMeterName;

  return (
    <DashboardLayout title={'Grupos'}>
      <div className={classes.root}>
        {/* <ClientInfobar corpData={corp} meterData={meter}/>  */}
        <Typography
          style={{color:'#A53693', marginBottom: theme.spacing.unit * 2 }}
          variant="h2"
        >{intl.formatMessage({id: 'app.groups.nav.groupLab2'})}</Typography>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumb}
          separator=":"
          sx={{marginTop: '0px'}}
        >
          {/* {isAllowed(user.permissions,['corporative_read'])&&<LinkMaterial
            color="inherit"
            component={NavLink}
            to="/home"
          >
            {intl.formatMessage({id: 'app.groups.nav.corp'})}     
          </LinkMaterial>} */}
          {isAllowed(user.permissions,['meter_read'])&& <LinkMaterial
            color="inherit"
            component={NavLink}
            style={{color:'#A53693'}}  
            to={{
              pathname: `/corporative/${corporativeId}`  
            }}
          >
            {intl.formatMessage({id: 'app.groups.nav.meters'})}
          </LinkMaterial>}
          

          <Typography
            className={classes.description}
            variant="h5"
          >
            { meterName ? meterName : intl.formatMessage({id: 'app.groups.nav.groupLab2'}) }
          </Typography>

         
        </Breadcrumbs>
        <div className={classes.content}>
          <GridGroup meter={meterId} /> 
        </div>
      </div>
    </DashboardLayout>
  )
}