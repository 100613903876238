export const GET_GENERAL_CONSUMES_SUCCESS = 'GET_GENERAL_CONSUMES_SUCCESS';

export const GET_MONTH_MOST_CONSUME_SUCCESS = 'GET_MONTH_MOST_CONSUME_SUCCESS';

export const GET_COMPARATIVE_YEARS_CONSUME_SUCCESS_CHARGE = 'GET_COMPARATIVE_YEARS_CONSUME_SUCCESS_CHARGE';

export const GET_DAY_WITH_MOST_CONSUME_SUCCESS = 'GET_DAY_WITH_MOST_CONSUME_SUCCESS';

export const GET_GENERAL_REPORT_SUCCESS = 'GET_GENERAL_REPORT_SUCCESS';

export const GET_RECEIPT_SUCCESS = 'GET_RECEIPT_SUCCESS';

export const GET_HISTORICAL_REPORT_STARTED = 'GET_HISTORICAL_REPORT_STARTED'
export const GET_HISTORICAL_REPORT_SUCCESS = 'GET_HISTORICAL_REPORT_SUCCESS'
export const GET_HISTORICAL_REPORT_FAILURE = 'GET_HISTORICAL_REPORT_FAILURE'
