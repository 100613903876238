/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */

import React from 'react';
import { useSelector } from 'react-redux';
import { isAllowed } from 'helpers/auth';


import { Breadcrumbs,Link as LinkMaterial } from '@material-ui/core';

import { NavLink } from 'react-router-dom';

// Material helpers
import { Typography , Grid } from '@material-ui/core';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { GridMeter } from './components';
import { Paper  } from 'components';
 

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import theme from 'theme';
const useStyles = makeStyles(styles)

export default function ProductList(props) {
  const intl = useIntl()
  const classes = useStyles();
  const {corpId} = props.match.params
  const meters = useSelector(state => state.meters.meters);
  const user = useSelector(state=>state.user.user);
  //const corporative = useSelector(state=>state.user.corporative)
  const corporative = useSelector( user.adser==1 ? state=>state.user.corporative : state=>state.user.user.corporative );
 
  const corporatives = useSelector( state=>state.corporatives.corporatives  );



   
  const getBanner = () => {
    let corporativeTMP= corporative;
   
    if(  user.adser===1   ){
      corporativeTMP= corporatives.find((m) => {
        return m.id == corporative.id
      }  );
    }else{
      corporativeTMP= corporative;
    }

    if( corporativeTMP==null ){
      return '';
    }

    return (

      <Paper  style={{ padding: 10, marginTop: 15 }}  >
        <Grid
           
          alignItems="center"          
          container
          direction="row"
          justify="flex-start"
        >
          <div className={classes.imageWrapper}>{getImage()}</div>
            
          <div> 
            <Typography
              className={classes.title}
              variant="h4"
            >
              {corporativeTMP.name}
            </Typography>

            <Typography
              className={classes.data}
              variant="h5"
            >
              {corporativeTMP.city} -  {corporativeTMP.state}
            </Typography>

            <Typography
              className={classes.data}
            >
              {intl.formatMessage({id: 'app.meters.banner.contact'})}: {corporativeTMP.contact.name}
            </Typography>         

            <Typography
              className={classes.data}
            >
              {intl.formatMessage({id: 'app.meters.banner.phone'})}: {corporativeTMP.contact.tel}
            </Typography>           
          </div>
        </Grid>
      </Paper>
    );
  }


  const getImage = () => {

    if(  corporative!=null ){

      if (corporative.logo != null && corporative.logo != '') {
        return (
          <img  
            alt="Product"
            className={classes.image}
            src={corporative.logo}
          />
        );
      } else {
        return (
          <img  
            alt="Product"
            className={classes.image}
            src="images/logo-metimur.png"
          />
        );
      }
    }
  };

  return (
    <DashboardLayout title={'Medidores'}>
      <div className={classes.root}>
        {/* <ClientInfobar corpData={corpData} /> */}
        <Typography
          style={{color:'#A53693', marginBottom: theme.spacing.unit * 2 }}
          variant="h2"
        >
          {intl.formatMessage({id: 'app.meters.nav.meters'})}s
        </Typography>
        {/* <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadcrumb}
        >
          {isAllowed(user.permissions,['corporative_read'])&&<LinkMaterial
            color="inherit"
            component={NavLink}
            to="/home"
          >
            {intl.formatMessage({id: 'app.meters.nav.corp'})}
          </LinkMaterial>}
          <LinkMaterial
            color="inherit"
            component={NavLink}
            to={`/corporative/${corpId}`}
          >
            {intl.formatMessage({id: 'app.meters.nav.meters'})}
          </LinkMaterial>         
        </Breadcrumbs> */}

        {getBanner()}

        <div className={classes.content}>
          <GridMeter coporative_id={corpId}/>  
        </div>
      </div>
    </DashboardLayout>
  )
}