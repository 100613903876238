/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { isAllowed } from 'helpers/auth';

// Material components
import {
  Grid,
  Typography,
  Breadcrumbs,
  Link as LinkMaterial,
  Box
} from '@material-ui/core';

import { NavLink } from 'react-router-dom';

// Shared layouts
import { Dashboard as DashboardLayout } from 'layouts';

// Custom components
import { ChargeTable } from './components';

import { useSelector, useDispatch } from 'react-redux';

// Component styles
import styles from './styles';
import { makeStyles } from '@material-ui/styles';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { countStatus } from 'js/actions/groupActions';
import theme from 'theme';
const useStyles = makeStyles(styles);

export default function ProductList(props) {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { charges } = useSelector(state => state.charges.charges);
  const { groupId } = props.match.params;
  const group =
    props.location && props.location.state ? props.location.state.group : null;

  const user = useSelector(state => state.user.user);
  //const concentratorid = props.props;
  const corpAdmin = useSelector(state => state.user.corporative);
  const corpUser = user.corporative;
  const corporativeId =
    corpUser === undefined || corpUser === null ? corpAdmin.id : corpUser.id;

  const meterAdmin = useSelector(state => state.user.meter);
  const meterUser = user.meters;
  const meters = useSelector(state => state.meters.meters);
  const meterId =
    meterUser === undefined || meterUser === null ? meterAdmin : meterUser.id;
  const meterObject = meters.find(m => m.id === meterId);
  const meter_id = meterUser ? meterUser : meterAdmin;
  const [activeCharges, setActiveCharges] = useState(0);
  const [totalCharges, setTotalCharges] = useState(0);
  const [inactiveCharges, setInactiveCharges] = useState(0);
  const groupType = group.type_energy;

  const storedCorpName = localStorage.getItem(`corpName_${corporativeId}`);
  const storedMeterName = localStorage.getItem(`meterName_${meterId}`);
  const storedGroupName = localStorage.getItem(`groupName_${groupId}`);

  useEffect(() => {
    if (meterUser && meterUser.name) {
      localStorage.setItem(`meterName_${meterId || meter_id}`, meterUser.name);
    }
  }, [meterUser, meterId]);

  useEffect(() => {
    if (corpAdmin && corpAdmin.name) {
      localStorage.setItem(`corpName_${corporativeId}`, corpAdmin.name);
    }
  }, [corpAdmin, corporativeId]);

  useEffect(() => {
    if (group && group.name) {
      localStorage.setItem(`groupName_${groupId}`, group.name);
    }
  }, [group, groupId]);

  const corpName =
    corpAdmin && corpAdmin.name ? corpAdmin.name : storedCorpName;
  const meterArray =
    meterUser === undefined || meterUser[0] === null
      ? false
      : meterUser[0]
        ? meterUser[0]
        : meterUser[1];
  const meterName =
    meterUser && meterArray.name ? meterArray.name : storedMeterName;
  const meterNameValidation = meterName || meter_id[0 || 1].name;
  const groupName = group && group.name ? group.name : storedGroupName;

  useEffect(() => {
    // dispatch(countStatus (groupId , countStatusFuncResp ));
  }, []);

  const countStatusFuncResp = data => {
    setActiveCharges(data.charges_actives);
    setTotalCharges(data.totals);
    setInactiveCharges(parseInt(data.totals) - parseInt(data.charges_actives));
  };

  const handleCountStatusChanged = () => {
    dispatch(countStatus(groupId, countStatusFuncResp));
  };

  return (
    <DashboardLayout title={'Mediciones'}>
      <div className={classes.root}>
        <Grid
          alignItems="center"
          container
          style={{ verticalAlign: 'middle' }}
        >
          <Grid
            item
            lg={8}
            md={8}
            xs={12}
          >
            {/* <ProductsToolbar meterId={meterId} groupId={groupId} /> */}
            <Typography
              style={{ color: '#A53693', marginBottom: theme.spacing.unit * 2 }}
              variant="h2"
            >
              {intl.formatMessage({ id: 'app.charges.nav.chargeLab2' })}
            </Typography>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Breadcrumbs
                arial-label="breadcrumb"
                className={classes.breadcrumb}
                separator=":"
                style={{ marginRight: theme.spacing.unit }}
              >
                {/* {isAllowed(user.permissions,['corporative_read']) && (
              <LinkMaterial
                color="inherit"
                component={NavLink}
                to="/home"
              >
                {intl.formatMessage({id: 'app.groups.nav.corp'})}
              </LinkMaterial>
            )} */}
                {isAllowed(user.permissions, ['meter_read']) && (
                  <LinkMaterial
                    color="inherit"
                    component={NavLink}
                    style={{ color: '#A53693' }}
                    to={{ pathname: `/corporative/${corporativeId}` }}
                  >
                    {intl.formatMessage({ id: 'app.groups.nav.meters' })}
                  </LinkMaterial>
                )}
                {isAllowed(user.permissions, ['group_read']) && (
                  <LinkMaterial
                    color="inherit"
                    component={NavLink}
                    to={{
                      pathname: `/meter/${meterId}`,
                      state: { meterId }
                    }}
                  >
                    {meterNameValidation
                      ? meterNameValidation
                      : intl.formatMessage({ id: 'app.groups.nav.groupLab2' })}
                  </LinkMaterial>
                )}
              </Breadcrumbs>
              <Typography style={{ fontWeight: 600, color: '#00AFEF' }}>
                {' '}
                |{' '}
              </Typography>
              <Breadcrumbs
                arial-label="breadcrumb"
                className={classes.breadcrumb}
                separator=":"
                style={{ marginLeft: theme.spacing.unit, color: '#A53693' }}
              >
                {isAllowed(user.permissions, ['group_read']) && (
                  <LinkMaterial
                    color="inherit"
                    component={NavLink}
                    to={{
                      pathname: `/meter/${meterId}`,
                      state: { meterId }
                    }}
                  >
                    {intl.formatMessage({ id: 'app.charges.nav.groupLab2' })}
                  </LinkMaterial>
                )}
                <Typography variant="h5">
                  {groupName
                    ? groupName
                    : intl.formatMessage({ id: 'app.groups.nav.groupLab2' })}
                </Typography>
              </Breadcrumbs>
            </Box>
          </Grid>

          <Grid
            alignItems="bottom"
            item
            lg={4}
            md={4}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
              display: 'flex-end'
            }}
            xs={12}
          >
            <div
              style={{
                textAlign: 'right',
                paddingTop: '5px',
                alignItems: 'center',
                verticalAlign: 'bottom',
                display: 'flex',
                justifyContent: 'right',
                marginRight: '4px'
              }}
            >
              <div
                style={{
                  marginRight: '5px',
                  fontSize: '14px',
                  fontFamily: 'Arial',
                  fontWeight: '600',
                  float: 'left',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    color: '#fff',
                    textShadow: '0 0 2px black',
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    width: '40px',
                    height: '40px',
                    marginRight: '1px',
                    marginLeft: '1px',
                    fontFamily: 'Arial',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    fontSize: '12px',
                    paddingTop: '12px',
                    fontWeight: '800',
                    backgroundImage: 'url(\'/images/charge_turn_on_count.png\')'
                  }}
                >
                  {activeCharges}{' '}
                </div>
                <div>On</div>
              </div>

              <div
                style={{
                  fontSize: '14px',
                  fontFamily: 'Arial',
                  fontWeight: '600',
                  float: 'left',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    color: '#fff',
                    textShadow: '0 0 2px black',
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    width: '40px',
                    height: '40px',
                    marginRight: '1px',
                    marginLeft: '1px',
                    fontFamily: 'Arial',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    fontSize: '12px',
                    paddingTop: '12px',
                    fontWeight: '800',
                    backgroundImage: 'url(\'/images/charge_turn_off_count.png\')'
                  }}
                >
                  {inactiveCharges}
                </div>
                <div>Off</div>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className={classes.content}>
          <ChargeTable
            groupId={groupId}
            handleCountStatusChanged={() => {
              handleCountStatusChanged();
            }}
            typeEnergy={groupType}
          />
        </div>
        {/* <Container
      styles={{marginRight: '-20px',
      marginBottom: '-20px'}}

      >
        <LinkFloat
        href= {`/newCharge/${group.meter_id}/${groupId}`}
        icon= "fa fa-plus"
        tooltip="Alta de Medicion"
        styles={{ backgroundColor:"#00AFEF", color: "#fff",width: "60px",height: "60px", fontSize:"1.5em"}}
        /> 
        <Button
          
          icon="fa fa-cog"
          rotate={true}
          styles={{backgroundColor: "#C69100", color: "#fff", width: "80px",height: "80px", fontSize:"1.5em"}}
        >
        </Button>
  
      </Container> */}
      </div>
    </DashboardLayout>
  );
}