export const GET_METER_STARTED = 'GET_METER_STARTED';
export const GET_METER_SUCCESS = 'GET_METER_SUCCESS';
export const GET_METER_FAILURE = 'GET_METER_FAILURE';

export const POST_METER_STARTED = 'POST_METER_STARTED';
export const POST_METER_SUCCESS = 'POST_METER_SUCCESS';
export const POST_METER_FAILURE = 'POST_METER_FAILURE';

export const DELETE_METER_SUCCESS = 'DELETE_METER_SUCCESS';

export const PUT_METER_STARTED = 'PUT_METER_STARTED';
