/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
import React, { Component, useEffect, useState , useLayoutEffect } from 'react';

// Externals
import classNames from 'classnames';
// Material helpers
import { Grid, CircularProgress, withStyles, Typography } from '@material-ui/core';

 

// Shared components
import {
  Portlet,
  PortletContent,  
} from 'components';

// Component styles
import styles from './styles';

import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getFPByMeter , fpConsume } from 'js/actions/meterDashboardActions';
import ReactSpeedometer from 'react-d3-speedometer'
import { useIntl } from 'react-intl';

const useStyles = makeStyles(styles);

export default function FPGauge(props) {
  const intl = useIntl()
  const meses= [ 
    intl.formatMessage({id: 'app.months.jan'}) ,
    intl.formatMessage({id: 'app.months.feb'}),
    intl.formatMessage({id: 'app.months.mar'}),
    intl.formatMessage({id: 'app.months.apr'}),
    intl.formatMessage({id: 'app.months.may'}),
    intl.formatMessage({id: 'app.months.jun'}),
    intl.formatMessage({id: 'app.months.jul'}),
    intl.formatMessage({id: 'app.months.aug'}),
    intl.formatMessage({id: 'app.months.sep'}),
    intl.formatMessage({id: 'app.months.oct'}),
    intl.formatMessage({id: 'app.months.nov'}),
    intl.formatMessage({id: 'app.months.dec'})

  ];
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);

  const year = new Date().getFullYear();
  const lastYear = year - 1;
  const years = [year, lastYear];
  const { corpId, meterId } = ids;

 
  const [fpRolado, setFPRolado] = React.useState( '0');
  const [fpRealTime, setFPRealTime] = React.useState( '0' );
  const [fpMesAnterior, setFPMesAnterior] = React.useState( '0' );
  const [mesAnterior, setMesAnterior] = React.useState( 0 );
  const [selFPId,setSelFPId] = React.useState( null );

  const currDate = new Date();

  const month= currDate.getMonth()+1;	// Month	[mm]	(1 - 12)
  const date= currDate.getDate();		// Day		[dd]	(1 - 31)
  let dateMinusX= currDate  
  dateMinusX.setDate(dateMinusX.getDate()-30);

  const getFPResponse=( data  )=>{
    let fpId=null;

    let sMonth='' + month;
    if(month<10){
      sMonth='0' + month;
    }

    let sDate='' + date;
    if( date<10 ){
      sDate='0' + date;
    }     
    let  dateEnd=year + sMonth + sDate;
 
    let previousYear= year;
    let previousMonth= month;
    if( month==1 ){
      previousYear=year-1;
      previousMonth=12;
    }else{
      previousYear=year;
      previousMonth=month - 1;
    }

    setMesAnterior( previousMonth );
      
    
    let currentHour= currDate.getHours()-1;    
    let hourIni=currentHour-5;
    let hourEnd=currentHour;
     
    var lastDayOfPreviousMonth = new Date(previousYear, previousMonth , 0);
    let lastDatePrevMonth= lastDayOfPreviousMonth.getDate();

    let sPrevMonth='' + previousMonth;
    if(previousMonth<10){
      sPrevMonth='0' + previousMonth;
    }

    let slastDatePrevMonth='' + lastDatePrevMonth;
    if( lastDatePrevMonth<10 ){
      slastDatePrevMonth='0' + lastDatePrevMonth;
    }

  
    let  previousMonthDateIni=previousYear + sPrevMonth + '01';
    let  previousMonthDateEnd=previousYear + sPrevMonth + slastDatePrevMonth;
 
    const year2 =  dateMinusX.getFullYear();
    const month2= dateMinusX.getMonth()+1;	 
    const date2= dateMinusX.getDate();

    let sMonth2='' + month2;
    if(month2<10){
      sMonth2='0' + month2;
    }

    let sDate2='' + date2;
    if( date2<10 ){
      sDate2='0' + date2;
    }

    let  date2Ini=year2 + sMonth2 +  sDate2 ;
    let  date2End=year + sMonth + sDate;
 
    if(data && data.length>0  ){
      fpId=data[0].id;    
      setSelFPId(fpId); 
      dispatch(fpConsume (  fpId , date2Ini ,  date2End , 0 , 0 , getFPRoladoConsumeResponse )); 
      dispatch(fpConsume (  fpId , dateEnd , dateEnd , hourIni , hourEnd ,  getFPRealTimeConsumeResponse )); 
      dispatch(fpConsume (  fpId , previousMonthDateIni  , previousMonthDateEnd , 0 , 0 ,  getFPPrevMonth )); 
     
    }


 
  }
    
  useEffect(() => {
    dispatch(getFPByMeter ( /* '123-456-678-123' */ meterId  , getFPResponse ));
  }, [ ]);


  const getFPRoladoConsumeResponse=( data  )=>{ 
    let val= Math.floor(parseFloat( data.value  )*100 ) ;
    setFPRolado(  val.toString()  )    
  }

  const getFPPrevMonth=( data )=>{  
    let val= parseFloat( data.value  )*100  ;
    val= parseInt(val);
    setFPMesAnterior(  val.toString()  )

  }
  
  const getFPRealTimeConsumeResponse=( data  )=>{ 
    let val= parseFloat( data.value  )*100  ;
    setFPRealTime(  val.toString()   );  
  }

  const segmentFormatter = (value) => {
    return `${value}%`
  }

  const formatPercentage = (value) => {
    if (value == null) {
      return '0.00';
    }
    return value; 
  };




  if( !selFPId ){
    return null;
  }else{
 
    return (


      <Portlet
        {...rest}
        className={rootClassName}
      >
      
        <PortletContent>
          <div  style={{ fontFamily: 'Lato, Helvetica Neue,Arial'  , textAlign:'center' }} >
            <Typography
              style={{ fontFamily:'Lato, Helvetica Neue,Arial' ,  marginTop:'35px' , marginBottom:'15px'   , fontWeight:'600'}}
              variant="h4"
            >{intl.formatMessage({id: 'app.meter.board.fpWidget.title'})}</Typography>

            <ReactSpeedometer
              currentValueText={`${formatPercentage(fpRolado) || 0}%`}
              customSegmentStops={[70, 80, 90,  100]}
              fontFamily={'Lato, Helvetica Neue,Arial'}
              height={200}
              labelFontSize={'23px'}
              maxValue={100}
              minValue={70}
              // height={200}
              needleColor={'#000'}
              needleHeightRatio={0.7}
              paddingHorizontal={17}
              paddingVertical={17}
              ringWidth={30}
              segmentColors={['#EB0000', '#FAC800', '#00C454' ]}
              textColor={'#000'}              
              segmentValueFormatter={segmentFormatter}
              value={formatPercentage(fpRolado) || 0}
              valueTextFontSize={'33px'}
              valueTextFontWeight={'600'}
              width={180}
            />

            
            <Typography
              style={{ fontFamily:'Lato, Helvetica Neue,Arial' ,  marginTop:'5px' ,  marginBottom:'15px'  , fontWeight:'600'}}
              variant="h5"
            >{intl.formatMessage({id: 'app.meter.board.fpWidget.closeLabel'})} {meses[ mesAnterior - 1 ]}</Typography>
            <Typography
              style={{ fontFamily:'Lato, Helvetica Neue,Arial' ,  marginTop:'15px' ,  marginBottom:'35px'  , fontWeight:'600'}}
              variant="h2"
            > {fpMesAnterior}%</Typography>

          </div>
        </PortletContent>
      </Portlet>
    
        
    );

  }


}


 