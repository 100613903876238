/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

// Palette
import palette from 'theme/palette';

// Externals
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Bar , Pie } from 'react-chartjs-2';

// Material helpers
import { Grid, Typography } from '@material-ui/core';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
} from 'components';

// Component styles
import styles from './styles';

import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { getDataByGroup } from 'js/actions/meterDashboardActions';
import { useIntl } from 'react-intl';
import theme from 'theme';

const useStyles = makeStyles(styles);

export default function AmountByGroupChart(props) {
  const intl = useIntl()
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ids, className, ...rest } = props;
  const rootClassName = classNames(classes.root, className);

  const year = new Date().getFullYear();
  const lastYear = year - 1;
  const years = [year, lastYear];
  const { corpId, meterId } = ids;
   

  const [data1, setData1] = React.useState( );
  const [data2, setData2] = React.useState( );
  const [numCols1,setNumCols1] = React.useState( );
  const [numCols2,setNumCols2] = React.useState( );

  useEffect(() => {
    dispatch(getDataByGroup(corpId, meterId, getDataByGroupResponse ));
  }, []); 

  function getColors(length) {
    let pallet = ['#0074D9', '#FF4136', '#2ECC40', '#FF851B', '#7FDBFF', '#B10DC9', '#FFDC00', '#001f3f', '#39CCCC', '#01FF70', '#85144b', '#F012BE', '#3D9970', '#111111', '#AAAAAA'];
    let colors = [];

    for (let i = 0; i < length; i++) {
      colors.push(pallet[i % (pallet.length - 1)]);
    }

    return colors;
  }
  
  const getDataByGroupResponse=( data  )=>{
    if( !data ){
      setData1([]);
      setData2([]);
      setNumCols1(8);
      setNumCols2(4);
      return;
    }

    if( data.length>8  ){
      setNumCols1(12);
      setNumCols2(12);
    }else{
      setNumCols1(8);
      setNumCols2(4);
    }

    let arrLabels=[]; 
    let arrValues=[];

    let arrLabels2=[]; 
    let arrValues2=[];      

    for( let idx=0;idx< data.length; idx++  ){
      arrLabels.push( data[idx].name );
      arrValues.push( data[idx].total );

      arrLabels2.push( data[idx].name+ ':'+ data[idx].total_kwh.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')   +' kWh' );
      arrValues2.push( data[idx].total_kwh );
    }



    setData1( {
      labels: arrLabels ,
      datasets: [
        {
          label: intl.formatMessage({id: 'app.meter.board.amountByGroup.dataset.labels.amount'}),
          yAxisID: 'A',
          backgroundColor: getColors(arrValues.length) ,            
          data: arrValues,
        },
         
          
      ]
    }  );    
      
      
    setData2( {
      labels: arrLabels2 ,
      datasets: [
        {
          label: intl.formatMessage({id: 'app.meter.board.consumptionByGroup.dataset.labels.amount'}),
           
          // backgroundColor: palette.primary.main,
          backgroundColor: getColors(arrValues2.length) ,
          data: arrValues2
        },
         
          
      ]
    }  );         







  }

  

     
  const formatMoney=(value)=>{
    return '$ 0.9999';

    /*
  if(!value){
    return "$ 0 ";
  }

   return "$ "+   value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ; 
   */

  }
     
    

  


 



  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },

    plugins: {
      datalabels: {
        backgroundColor: function(context) {
          return context.dataset.backgroundColor;
        },
        borderColor: 'white',
        borderRadius: 25,
        borderWidth: 3,
        color: 'white',
        font: {
          weight: 'bold'
        },
        padding: 36,


      }
    } ,
   
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary   ,
      callbacks: {

        label: function(tooltipItem, data) {         
          return  `${   data.datasets[tooltipItem.datasetIndex ].label + ' $ '+ tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }`;
        },   

       
        /*
        label: function(tooltipItem, data) {         
          return  `${   data.datasets[tooltipItem.datasetIndex ].label + " $ "+ tooltipItem.value }`;
        },*/
        
        /*
        afterLabel: function(tooltipItem, data) {
           
               return  `${ "$" + tooltipItem.value }`;
        }*/
      },
    },
    layout: { padding: 50 },
    scales: {
      xAxes: [
        {
          barThickness: 20,
          maxBarThickness: 20,
          ticks: {
            fontColor: palette.text.secondary  
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [{
        id: 'A',
        position: 'left',
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0,
          callback: function(value) {
            return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} $`;
          }
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }]
    }
  
  };




  const options2 = {
    
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        backgroundColor: function(context) {
          return context.dataset.backgroundColor;
        },
        borderColor: 'white',
        borderRadius: 25,
        borderWidth: 3,
        color: 'white',
        font: {
          weight: 'bold'
        },
        padding: 10,


      }
    } ,  
    legend: { display: true , margin: 50  },
    datalabels: {
      display: true,
      color: '#ff0000'
      
    },
   
    tooltips: {
      enabled: true,
      mode: 'index',
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary   ,
      callbacks: {
        
        label: function(tooltipItem, data) {              
          return  `${   data.datasets[tooltipItem.datasetIndex ].label +' '+ data.datasets[tooltipItem.datasetIndex ].data[tooltipItem.index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +' kWh'  }`;
        },   
        /*
        label: function(tooltipItem, data) {         
          return  `${   data.datasets[tooltipItem.datasetIndex ].label + " $ "+ tooltipItem.value }`;
        },*/
        
        /*
        afterLabel: function(tooltipItem, data) {
           
               return  `${ "$" + tooltipItem.value }`;
        }*/
      },
    }
  };

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        lg={numCols1}
        md={numCols1}
        xl={numCols1}
        xs={12}
      >
        <Portlet
          {...rest}
          className={rootClassName}
        >
          <PortletHeader noDivider >
            <PortletLabel title={intl.formatMessage({id: 'app.meter.board.amountByGroup.title'})} />
            <PortletToolbar />
          </PortletHeader>
          <PortletContent style={{paddingLeft: theme.spacing.unit, paddingRight: theme.spacing.unit}}>
            <div className={classes.chartWrapper}>
              <Bar
                data={data1}
                key={Math.random()}
                options={options}
                redraw
              /> 
            </div>
          </PortletContent>
        </Portlet>
      </Grid>

      <Grid
        item
        lg={numCols2}
        md={numCols2}
        xl={numCols2}
        xs={12}
      >

        <Portlet
          {...rest}
          className={rootClassName}
        >
          <PortletHeader
            noDivider
          >
            <PortletLabel title={intl.formatMessage({id: 'app.meter.board.consumptionByGroup.title'})} />
            <PortletToolbar />
          </PortletHeader>
          <PortletContent>
            <div className={classes.chartWrapper}>
              <Pie
                data={data2}
                key={Math.random()}
                options={options2}
                redraw
              />
            </div>
          </PortletContent>
        </Portlet>
      </Grid>
    </Grid>
  );
}

AmountByGroupChart.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};
