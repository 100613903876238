import React, { Component, Fragment } from 'react';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';

// Material helpers
import { withStyles, withWidth } from '@material-ui/core';

// Custom components
import { Sidebar, Topbar, Footer } from './components';

// Component styles
import styles from './styles';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const isMobile = ['xs', 'sm', 'md'].includes(props.width);

    const cerrado = props.cerrado || null;

    this.state = {
      isOpen: !( isMobile || cerrado === 'true' ) 
    };
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleToggleOpen = () => {    
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  handleOutsideClick = (event) => {
    if (['xs', 'sm', 'md'].includes(this.props.width) && this.state.isOpen && !this.node.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  componentDidMount() {
    if (['xs', 'sm', 'md'].includes(this.props.width)) {
      document.addEventListener('mouseup', this.handleOutsideClick);
    }
  }

  componentWillUnmount() {
    if (['xs', 'sm', 'md'].includes(this.props.width)) {
      document.removeEventListener('mouseup', this.handleOutsideClick);
    }
  }


  render() {
    const { classes, width, title, children } = this.props;
    const { isOpen } = this.state;

    const isMobile = ['xs', 'sm', 'md'].includes(width);
    const shiftTopbar = !isOpen && !isMobile;
    const shiftContent = !isOpen && !isMobile;

    return (
      <Fragment>
        <Topbar
          className={classNames(classes.topbar, {
            [classes.topbarShift]: shiftTopbar
          })}
          isSidebarOpen={!isOpen}
          mobile={isMobile}
          onToggleSidebar={this.handleToggleOpen}
          title={title}
        />
        <div
          onClick={isOpen ? this.handleOutsideClick : null}
          ref={(node)=>(this.node = node)}
        >
          <Sidebar
            classes={classes.drawerPaper}
            close={isMobile ? this.handleClose : null}
            mobile={isMobile}
            open={isMobile ? isOpen : !isOpen}
          />
        </div>
        <div
          onClick={isOpen ? this.handleOutsideClick : null}
          ref={(node)=>(this.node = node)}
        >
          <Sidebar
            classes={classes.drawerPaper}
            close={isMobile ? this.handleClose : null}
            mobile={isMobile}
            open={isMobile ? isOpen : !isOpen}
          />
        </div>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: shiftContent
          })}
        >
          {children}
          <Footer />
        </main>
      </Fragment>
    );
  }
}

Dashboard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string.isRequired
};

export default compose(
  withStyles(styles),
  withWidth()   
)(Dashboard);

 