import * as meterActionTypes from '../constants/meterActions-types';

// const executeGetCorporativeSuccess = (state, action) => {
//     return Object.assign({}, state , {
//             corporatives: state.corporatives.concat(action.corporatives)
//         })
// }

function meterReducer(state = { meters: [], loading: true }, action) {
  switch (action.type) {
    case meterActionTypes.POST_METER_STARTED:
      return {
        ...state,
        loading: true
      };
    case meterActionTypes.POST_METER_SUCCESS:
      return {
        ...state,
        meters: state.meters.push(action.payload),
        loading: false
      };
    case meterActionTypes.POST_METER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    case meterActionTypes.GET_METER_STARTED:
      return {
        ...state,
        loading: true
      };
    case meterActionTypes.GET_METER_SUCCESS:
      return { ...state, meters: action.meters, loading: false };

    case meterActionTypes.GET_METER_FAILURE:
      return { ...state, meters: [], loading: false };      

    case meterActionTypes.DELETE_METER_SUCCESS:
      return {
        ...state,
        meters: state.meters.filter(({ id }) => id !== action.payload.id)
      };

    case meterActionTypes.PUT_METER_STARTED:
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
}

export default meterReducer;
